export const parseDate = (d) => {
    let date = ''
    try {
        let year = d.split('-')[0].slice(2)
        let month = d.split('-')[1][0] === '0' ? d.split('-')[1].slice(1) : d.split('-')[1]
        let day = d.split('-')[2].split('T')[0][0] === '0' ? d.split('-')[2].split('T')[0].slice(1) : d.split('-')[2].split('T')[0]
        date = `${month}/${day}/${year}` 
    } catch (err) {
        date = 'N/A'
    }
    return date
}

export const toCurrency = (n) => { 
    let currency = ''
    try {
        currency = '$' + parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    } catch (err) {
        currency = '$0.00'
    }
    return currency
}

export const reduceZip = (z) => {
    if (z && z.length > 5) {
        return z.substring(0,5)
    } else {
        return z
    }
}

export const currentCycle = (new Date()).getFullYear() % 2 === 0 ? (new Date()).getFullYear() : (new Date()).getFullYear() + 1