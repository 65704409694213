import React, {Component} from 'react'
import { withRouter, Route } from 'react-router-dom'
import * as routes from '../constants/routes';
import SearchDisbursements from './searchdisbursements'
import DisplayDisbursements from '../views/displaydisbursements';

class Disbursements extends Component {

    render() {
        return(
            <div>
                <SearchDisbursements />
                <Route path={routes.DISBURSEMENTS + "/:search"} component={DisplayDisbursements}/>
            </div>  
        )
    }
}


export default withRouter(Disbursements)