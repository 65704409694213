import React, {Component} from 'react'
import { Route } from 'react-router-dom'
import SearchCandidatesAndCommittees from './searchcandidatescommittees.js'
import DisplayMatchingCommittees from '../views/displaycommittee'
import DisplayMatchingCandidates from '../views/displaycandidates'
import Committee from './committee'
import HouseRaceReport from '../views/summary/housecommittee'
import * as routes from '../constants/routes';

class CandidatesAndCommittees extends Component {

    render() {
        return(
            <div>
                <Route
                    path={routes.COMMITTEES + "/search"}
                    component={SearchCandidatesAndCommittees} />
                <Route 
                    path={routes.COMMITTEES + "/search/:search"} 
                    component={DisplayMatchingCandidates}
                />
                <Route 
                    path={routes.COMMITTEES + "/search/:search"} 
                    component={DisplayMatchingCommittees}
                />
                <Route
                    path={routes.COMMITTEES + "/id/:committeeID"}
                    exact
                    component={Committee}
                />
                <Route
                    path={routes.COMMITTEES + "/id/summary/:committeeID/:cycle"}
                    component={HouseRaceReport}
                /> 
            </div>  
        )
    }
}

export default CandidatesAndCommittees