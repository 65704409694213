import React, {Component} from 'react'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'
import ReactLoading from 'react-loading'
import {makeRequestForFECData} from '../constants/api'
import * as routes from '../constants/routes';
import * as functions from '../constants/functions'

class DisplayMatchingCommittees extends Component {

    constructor () {
        super () 
        this.state = {
            searchTextDisplay: null,
            searchParams: null,
            results: null,
            loading: false,
        }
      }

    callCommitteeSearch = (params, results) => {
        const endpoint = '/committees/'
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results) {
                const formattedResults = data.results.map((record) => {
                    const row = ({
                        'name': <Link to={'../..' + routes.COMMITTEES + '/id/' + record.committee_id}>{record.name}</Link>,
                        'committee_id': record.committee_id,
                        'party': record.party || "NONE",
                        'cycles': record.cycles,
                        'candidates': record.candidate_ids || '',
                        'first_report': functions.parseDate(record.first_file_date),
                        'last_report': functions.parseDate(record.last_file_date),
                        'filings': <a href={'https://www.fec.gov/data/committee/' + record.committee_id + '/?tab=filings'} target="_blank" rel="noopener noreferrer">FEC Files</a>

                    })
                    return row    
                })
                results.push(...formattedResults)
                this.setState({ loading: false })
                if (results.length === 0) {
                    this.setState({
                        results: null,
                        searchTextDisplay: `There Are No Committees Matching "${this.state.searchParams.q}"`
                    })
                } else {
                    this.setState({
                        results: results,
                        searchTextDisplay: `Committees Matching "${this.state.searchParams.q}"`
                    })
                }
            } else {
                this.setState({
                    results: null,
                    searchTextDisplay: 'There was an error retreiving this data. Please try again.'
                })
            }
        })
    }

    getSearchResults = (search) => {
        let params = qs.parse(search)
        params['per_page'] = '100'
        params['sort'] = '-last_file_date'
        this.setState({
            searchParams: params,
            loading: true
        })
        this.callCommitteeSearch(params, [])
    }
    
    componentDidMount() {
        this.getSearchResults(this.props.match.params.search)
    }

    componentWillReceiveProps (nextProps) {
        this.getSearchResults(nextProps.match.params.search)
    }

    electionLink (cycle, params) {
        const queryParams = `cycle=${cycle}&${params}`
        return (`..${routes.ELECTIONS}/${queryParams}`)
    }

    render() {

        const data = this.state.results

        return(
            <div className="container card results">
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    this.state.searchTextDisplay ?
                    <h3>{this.state.searchTextDisplay}<br /></h3> : null
                }
                {
                    (data && !this.state.loading) ?
                    <table>
                        <tbody>
                        <tr>
                            <th>Committee</th>
                            <th>Party</th>
                            <th>Link To Filings</th>
                            <th>First Filing</th>
                            <th>Most Recent Filing</th>
                        </tr>
                        
                        {data.map(committee => {
                            return(
                                <tr key={committee.committee_id}>
                                    <td>{committee.name}</td>
                                    <td>{committee.party}</td>
                                    <td>{committee.filings}</td>
                                    <td className="numeric">{committee.first_report}</td>
                                    <td className="numeric">{committee.last_report}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : null
                }
            </div>
        )
    }
}

export default withRouter(DisplayMatchingCommittees)