import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import * as functions from '../constants/functions'
import ReactLoading from 'react-loading'
import {makeRequestForFECData} from '../constants/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class ElectionIESpending extends Component {

    constructor () {
        super () 
        this.state = {
            searchTextDisplay: null,
            searchParams: null,
            results: [],
            spenders: [],
            loading: false,
        }
      }

    callItemizedIESpending = (params, results) => {
        const endpoint = '/schedules/schedule_e/'
        if (params.length > 0) {
            const spender = params.pop()
            spender['per_page'] = 100
            makeRequestForFECData(endpoint, spender).then(data => {
                if (data.results) {
                    const formattedResults = data.results.map((record) => {
                        const row = ({
                            'committee_name': `<a href=${record.pdf_url} target="_blank" rel="noopener noreferrer">${record.committee.name}</a>`,
                            'support_oppose': record.support_oppose_indicator === 'S' ? "Support" : "Oppose",
                            'candidate': record.candidate_name,
                            'expenditure_description': record.expenditure_description,
                            'expenditure_date': record.expenditure_date,
                            'expenditure_amount': record.expenditure_amount,
                        })
                        return row
                    })
                    results.push(...formattedResults)
                    if (data.pagination.last_indexes) {
                        params['last_index'] = data.pagination.last_indexes.last_index
                        params['last_contribution_receipt_date'] = data.pagination.last_indexes.last_contribution_receipt_date
                        this.callItemizedIESpending(params, results)
                    } else {
                        this.callItemizedIESpending(params, results)
                    }
                } else {
                    this.setState({
                        loading: false,
                        spenders: [],
                        searchTextDisplay: 'There was an error retreiving candidates for this election. Please try again.'
                    })
                }
            })    
        } else {
            this.setState({ 
                results: results,
                loading: false
            })
        }
    }
    
    callIETotalSpending = (params, itemizedResults, totalResults) => {
        const endpoint = '/schedules/schedule_e/by_candidate/'
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results) {
                // const newItemizedResults = data.results.map((record) => {
                //     const row = ({
                //         'committee_id': record.committee_id,
                //         'candidate_id': record.candidate_id,
                //         'cycle': record.cycle,
                //     })
                //     return row
                // })
                // itemizedResults.push(...newItemizedResults)

                const newTotalResults = data.results.map((record) => {
                    const row = ({
                        'committee_name': `<a href='https://www.fec.gov/data/committee/${record.committee_id}/?tab=spending' target="_blank" rel="noopener noreferrer">${record.committee_name}</a>`,
                        'candidate_name': record.candidate_name,
                        'support_oppose': record.support_oppose_indicator === 'S' ? "Support" : "Oppose",
                        'total': record.total
                    })
                    return row
                })
                totalResults.push(...newTotalResults)

                if (data.pagination.last_indexes) {
                    params['last_index'] = data.pagination.last_indexes.last_index
                    params['last_contribution_receipt_date'] = data.pagination.last_indexes.last_contribution_receipt_date
                    this.callIETotalSpending(params, itemizedResults, totalResults)
                } else if (totalResults.length === 0) {
                    this.setState({
                        spenders: totalResults,
                        searchTextDisplay: `There Is No ${this.setRaceText(params)}`,
                        loading: false
                    })
                } else {
                    this.setState({
                        spenders: totalResults,
                        searchTextDisplay: this.setRaceText(params),
                        loading: false
                    })
                    // this.callItemizedIESpending(itemizedResults, [])
                }
            } else {
                this.setState({
                    loading: false,
                    spenders: [],
                    searchTextDisplay: 'There was an error retreiving candidates for this election. Please try again.'
                })
            }
        })
    }

    setRaceText = (p) => {
        if (p.office === 'president') {
            return `IE Spending For Or Against Candidates For President In ${p.cycle}`
        } else if (p.office === 'senate') {
            return `IE Spending For Or Against Candidates For The Senate In ${p.state} In ${p.cycle}`
        } else {
            if (p.district === '00') {
                return `IE Spending For Or Against Candidates For The House In ${p.state}-AL In ${p.cycle}`
            }
            return `IE Spending For Or Against Candidates For The House In ${p.state}-${p.district} In ${p.cycle}`
        }
    }

    getSearchResults = (search) => {
        let params = qs.parse(search)
        params['per_page'] = '100'
        this.setState({
            loading: true,
            spenders: []
        })
        this.callIETotalSpending(params, [], [])
    }
    
    componentDidMount() {
        this.getSearchResults(this.props.match.params.search)
    }

    componentWillReceiveProps (nextProps) {
        this.getSearchResults(nextProps.match.params.search)
    }

    render() {

        const totalDisplayColumns = [{
            Header: 'Committee',
            accessor: 'committee_name',
            Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }}></div>),
            width: 430,
        },{
            Header: 'Support/Oppose',
            accessor: 'support_oppose',
            width: 100
        },{
            Header: 'Candidate',
            accessor: 'candidate_name',
            width: 180
        },{
            Header: 'Amount',
            accessor: 'total',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        }]


        const itemizedDisplayColumns = [{
            Header: 'Committee',
            accessor: 'committee_name',
            Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }}></div>),
            width: 220,
        },{
            Header: 'Support/Oppose',
            accessor: 'support_oppose',
            width: 100
        },{
            Header: 'Candidate',
            accessor: 'candidate',
            width: 120
        },{
            Header: 'For',
            accessor: 'expenditure_description',
            width: 220
        },{
            Header: 'Date',
            accessor: 'expenditure_date',
            Cell: row => (functions.parseDate(row.value)),
            className: 'numeric',
            width: 70,
        },{
            Header: 'Amount',
            accessor: 'expenditure_amount',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        }]

        const totalData = this.state.spenders
        const itemizedData = this.state.results

        return(
            <div className="container card results">
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    (this.state.searchTextDisplay && !this.state.loading) ?
                    <h3>{this.state.searchTextDisplay}</h3> : 
                    null
                }
                {
                    (totalData.length > 0 && !this.state.loading) ?
                    <div>
                        {/* <h3> Total Independent Expenditure Spending</h3> */}
                    <ReactTable
                        data={totalData}
                        columns={totalDisplayColumns}
                        showPagination={false}
                        defaultPageSize={totalData.length}
                        className="-striped -highlight"
                        resizable={false}
                        defaultSorted={[{
                            id: 'total',
                            desc: true,
                          }]}
                    /></div> : 
                    null
                }
                {
                    (itemizedData.length > 0 && !this.state.loading) ?
                    <div><br />
                        <h3>Itemized Expenditures</h3>
                    <ReactTable
                        data={itemizedData}
                        columns={itemizedDisplayColumns}
                        showPagination={false}
                        defaultPageSize={itemizedData.length}
                        className="-striped -highlight"
                        resizable={false}
                        defaultSorted={[{
                            id: 'expenditure_date',
                            desc: true,
                          }]}
                    /></div> : 
                    null
                }
            </div>
        )
    }
}

export default withRouter(ElectionIESpending)