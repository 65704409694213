import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import * as functions from '../../constants/functions'
import {makeRequestForFECData} from '../../constants/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class States extends Component {

    constructor (props) {
        super (props) 
        this.state = {
            loading: false
        }
    }

    getCommitteeData = (committeeID, params, results) => {
        const endpoint = `/committee/${committeeID}/schedules/schedule_a/by_state/`
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results && data.results.length > 0) {
                const formattedResults = data.results.map((record) => {
                    const row = {
                        'state': record.state || 'NONE LISTED',
                        'total': record.total,
                        'count': record.count,
                        'average': (record.total / record.count)
                    }
                    return row
                })
                results.push(...formattedResults)
                if (data.pagination.pages !== params['page']) {
                    params['page'] += 1
                    this.getCommitteeData(committeeID, params, results)
                } else {
                    this.setState({
                        results: results,
                        loading: false,
                    })
                }
            } else {
                this.setState({
                    results: null,
                    loading: false,
                })
            }
        })
    }

    getSearchResults = (committeeID, cycle) => {
        let params = {
            'page': 1,
            'per_page': 100,
            'sort': '-total',
            'cycle': cycle,
        }
        this.setState({
            loading: true
        })
        this.getCommitteeData(committeeID, params, [])
    }

    componentWillReceiveProps(props) {
        const committeeID = props['committeID']
        const cycle = props['cycle']

        this.setState({
            'committeeID': committeeID, 
            'cycle': cycle, 
            'errorText': null, 
        })

        this.getSearchResults(committeeID, cycle)
    }

    getState = (ab) => {
        const states = {
            "AL": "Alabama",
            "AK": "Alaska",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "FL": "Florida",
            "GA": "Georgia",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PA": "Pennsylvania",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VA": "Virginia",
            "WA": "Washington",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming",
            "AS": "American Samoa",
            "DC": "District of Columbia",
            "FM": "Federated States of Micronesia",
            "GU": "Guam",
            "MH": "Marshall Islands",
            "MP": "Northern Mariana Islands",
            "PW": "Palau",
            "PR": "Puerto Rico",
            "VI": "Virgin Islands",
            "AA": "Armed Forces Americas",
            "AE": "Armed Forces Africa/Canada/Europe/Middle East",
            "AP": "Armed Forces Pacific"
        }
        return states[ab] || "Not Found"
    }

    render() {

        const displayColumns = [{
            Header: 'State',
            accessor: 'state',
            Cell: row => (this.getState(row.value)),
            width: 300
        },{
            Header: 'Total Contributions',
            accessor: 'total',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Number of Contributions',
            accessor: 'count',
            className: 'numeric'
        },{
            Header: 'Average Contribution',
            accessor: 'average',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        }]

        const data = this.state.results

        return(
            <div>
                <h2>Donors By State</h2>
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    (data && !this.state.loading) ?
                    <ReactTable
                        data={data}
                        columns={displayColumns}
                        showPagination={true}
                        defaultPageSize={10}
                        showPageSizeOptions={false}
                        className="-striped -highlight"
                        resizable={false}
                    /> : 
                    null
                }
            </div>
        )
    }

}

export default States