import React, {Component} from 'react'
import { withRouter, Route } from 'react-router-dom'
import * as routes from '../constants/routes';
import DisplayElections from '../views/displayelections';
import ElectionIESPending from '../views/displayelectioniespending';
import SearchElections from './searchelections'

class Elections extends Component {

    render() {
        return(
            <div>
                <Route path={routes.ELECTIONS} component={SearchElections}/>
                <Route path={routes.ELECTIONS + "/:search"} component={DisplayElections}/>
                <Route path={routes.ELECTIONS + "/:search"} component={ElectionIESPending}/>
            </div>  
        )
    }
}


export default withRouter(Elections)