import React, {Component} from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Navigation from './navigation'
import * as routes from '../constants/routes';

import Contributions from './contributions'
import Disbursements from './disbursements'
import CandidatesAndCommittees from './candidatesandcommittees'
import Elections from './election'
import Committee from './committee'
import './App.css';

class App extends Component {

  render() {
    return (
        <div className="main">
          <div className="header"><a href="https://www.delvedc.com" target="_blank" rel="noopener noreferrer" >
            <img src="/delve-logo.png" height="40" alt="Delve" /></a>
          </div>
          <Navigation />
          <Switch>
            <Route path={routes.CONTRIBUTIONS} component={Contributions}/>
            <Route path={routes.DISBURSEMENTS} component={Disbursements}/>
            <Route path={routes.COMMITTEES} component={CandidatesAndCommittees}/>
            <Route path={routes.ELECTIONS} component={Elections}/>
            <Route path={routes.COMMITTEES + '/:committeeID/:cycle?'} component={Committee}/>
            <Redirect from="/" to={routes.CONTRIBUTIONS} />
          </Switch>
        </div>
    )
  }
}

export default App;