import React, {Component} from 'react'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'
import ReactLoading from 'react-loading'
import { makeRequestForFECData } from '../constants/api'
import * as routes from '../constants/routes';

class DisplayMatchingCandidates extends Component {

    constructor () {
        super () 
        this.state = {
            searchTextDisplay: null,
            searchParams: null,
            results: null,
            loading: false,
        }
      }

    callCandidateSearch = (params, results) => {
        const endpoint = '/candidates/search/'
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results) {
                const formattedResults = data.results.map((record) => {
                    const row = ({
                        'name': record.name,
                        'candidate_id': record.candidate_id,
                        'party': record.party || "N/A",
                        'committees': record.principal_committees.map(committee => {
                            return {
                                'committee_name': committee.name,
                                'committee_id': committee.committee_id
                            }
                        }),
                        'cycles': record.cycles,
                        'race': record.office_full === 'President' ? `President` :
                        record.office_full === 'Senate' ? `Senate ${record.state}` : `House ${record.state}-${record.district_number}`,
                        'election_key': record.office_full === 'President' ? `office=president` :
                        record.office_full === 'Senate' ? `office=senate&state=${record.state}` : `office=house&state=${record.state}&district=${record.district_number}`
                    })
                    return row
                })
                results.push(...formattedResults)
                this.setState({ loading: false })
                if (results.length === 0) {
                    this.setState({
                        results: null,
                        searchTextDisplay: `There Are No Candidates Matching "${this.state.searchParams.q}"`
                    })
                } else {
                    this.setState({
                        results: results,
                        searchTextDisplay: `Candidates Matching "${this.state.searchParams.q}"`
                    })
                }
            } else {
                this.setState({
                    results: null,
                    searchTextDisplay: 'There was an error retreiving this data. Please try again.'
                })
            }
        })
    }

    getSearchResults = (search) => {
        let params = qs.parse(search)
        params['per_page'] = '100'
        this.setState({
            searchParams: params,
            loading: true
        })
        this.callCandidateSearch(params, [])
    }
    
    componentDidMount() {
        this.getSearchResults(this.props.match.params.search)
    }

    componentWillReceiveProps (nextProps) {
        this.getSearchResults(nextProps.match.params.search)
    }
    
    electionLink (cycle, params) {
        const queryParams = `cycle=${cycle}&${params}`
        return (`../..${routes.ELECTIONS}/${queryParams}`)
    }

    committeeLink (params) {
        const queryParams = `${params}`
        return (`..${routes.ELECTIONS}/${queryParams}`)
    }

    render() {

        const data = this.state.results

        return(
            <div className="container card results">
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    this.state.searchTextDisplay &&
                    <h3>{this.state.searchTextDisplay}<br /></h3>
                }
                {
                    (data && !this.state.loading) ?
                    <table>
                        <tbody>
                        <tr>
                            <th>Candidate</th>
                            <th>Party</th>
                            <th>Election</th>
                            <th>Committee(s)</th>
                            <th>Active Cycles</th>
                        </tr>
                        
                        {data.map(candidate => {
                            return(
                                <tr key={candidate.candidate_id}>
                                    <td>{candidate.name}</td>
                                    <td>{candidate.party}</td>
                                    <td>{candidate.race}</td>
                                    <td>{candidate.committees.map(committee => {
                                        return(
                                            <span key={candidate.candidate_id}>
                                                <Link to={'../..' + routes.COMMITTEES + '/id/' + committee.committee_id}>{committee.committee_name}</Link><br />
                                            </span>
                                        )
                                    })}</td>
                                    <td>{candidate.cycles.map(cycle => {
                                        return(
                                            <span key={candidate.candidate_id + cycle}>
                                            <Link to={this.electionLink(cycle, candidate.election_key)}>{cycle}</Link><br />
                                            </span>
                                        )
                                    })}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : null
                }
            </div>
        )
    }
}

export default withRouter(DisplayMatchingCandidates)