import React, {Component} from 'react'
import { withRouter, Route } from 'react-router-dom'
import * as routes from '../constants/routes';
import SearchContributions from './searchcontributions'
import DisplayContributions from '../views/displaycontributions';

class Contributions extends Component {

    render() {
        return(
            <div>
                <SearchContributions />
                <Route path={routes.CONTRIBUTIONS + "/:search"} component={DisplayContributions}/>
            </div>  
        )
    }
}


export default withRouter(Contributions)