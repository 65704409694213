import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import * as functions from '../../constants/functions'
import {makeRequestForFECData} from '../../constants/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Employers extends Component {

    constructor (props) {
        super (props) 
        this.state = {
            loading: false
        }
    }

    getCommitteeData = (committeeID, params, results) => {
        const endpoint = `/committee/${committeeID}/schedules/schedule_a/by_employer/`
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results && data.results.length > 0) {
                const formattedResults = data.results.map((record) => {
                    const row = {
                        'employer': record.employer || 'NONE LISTED',
                        'total': record.total,
                        'count': record.count,
                        'average': (record.total / record.count)
                    }
                    return row
                })
                results.push(...formattedResults)
                if (data.pagination.pages !== params['page']) {
                    params['page'] += 1
                    this.getCommitteeData(committeeID, params, results)
                } else {
                    this.setState({
                        results: results,
                        loading: false,
                    })
                }
            } else {
                this.setState({
                    results: null,
                    loading: false,
                })
            }
        })
    }

    getSearchResults = (committeeID, cycle) => {
        let params = {
            'page': 1,
            'per_page': 100,
            'sort': '-total',
            'cycle': cycle,
        }
        this.setState({
            loading: true
        })
        this.getCommitteeData(committeeID, params, [])
    }

    componentWillReceiveProps(props) {
        const committeeID = props['committeID']
        const cycle = props['cycle']
        this.setState({
            'committeeID': committeeID, 
            'cycle': cycle, 
            'errorText': null, 
        })
        this.getSearchResults(committeeID, cycle)
    }

    render() {

        const displayColumns = [{
            Header: 'Employer',
            accessor: 'employer',
            Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }}></div>),
            width: 300
        },{
            Header: 'Total Contributions',
            accessor: 'total',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Number of Contributions',
            accessor: 'count',
            className: 'numeric'
        },{
            Header: 'Average Contribution',
            accessor: 'average',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        }]

        const data = this.state.results

        return(
            <div>
                <h2>Donors By Employer</h2>
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    (data && !this.state.loading) ?
                    <ReactTable
                        data={data}
                        columns={displayColumns}
                        showPagination={true}
                        defaultPageSize={10}
                        showPageSizeOptions={false}
                        className="-striped -highlight"
                        resizable={false}
                    /> : 
                    null
                }
            </div>
        )
    }

}

export default Employers