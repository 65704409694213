import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import * as functions from '../../constants/functions'
import {makeRequestForFECData} from '../../constants/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Chart from "react-google-charts";

class Summary extends Component {

    constructor (props) {
        super (props) 
        this.state = {
            results: null,
            chartData: [],
            loading: true,
            errorText: null,
        }
    }

    getCommitteeReports = (committeeID, params, results) => {
        const endpoint = `/committee/${committeeID}/filings/`
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results && data.results.length > 0) {
                const formattedResults = data.results.map((record) => {
                    const row = {
                        'report_title': `<a href=${record.pdf_url} target="_blank">${record.report_type_full} ${record.report_year}</a>`,
                        'total_receipts': record.total_receipts,
                        'total_disbursements': record.total_disbursements,
                        'debt': record.debts_owed_by_committee,
                        'cash_on_hand_end_period': record.cash_on_hand_end_period,
                        'coverage_start_date': record.coverage_start_date,
                        'coverage_end_date': record.coverage_end_date,
                        'receipt_date': record.receipt_date,
                        'report_name': `${record.report_type_full} ${record.report_year}`,
                        'amendment_indicator': record.amendment_indicator || null
                    }
                    return row
                })
                results.push(...formattedResults)
                if (data.pagination.pages !== params['page']) {
                    params['page'] += 1
                    this.getCommitteeReports(committeeID, params, results)
                } else {
                    let chartData = this.getChartData(results)
                    this.setState({
                        results: results,
                        chartData: chartData,
                        loading: false,
                    })
                }
            } else {
                this.setState({
                    results: null,
                    loading: false,
                })
            }
        })
    }

    getSearchResults = (committeeID, cycle) => {
        let params = {
            'page': 1,
            'per_page': 100,
            'sort': 'coverage_end_date',
            'is_amended': false,
            'form_type': 'F3',
            'cycle': cycle
        }
        this.setState({
            loading: true
        })
        this.getCommitteeReports(committeeID, params, [])
    }

    componentWillReceiveProps(props) {
        const committeeID = props['committeID']
        const cycle = props['cycle']
        this.setState({
            'committeeID': committeeID, 
            'cycle': cycle, 
            'errorText': null, 
        })
        this.getSearchResults(committeeID, cycle)
    }

    getChartData = (results) => {
        const chartedResults = results.map((record) => {
            try {
                const row = [
                    functions.parseDate(record.coverage_end_date),
                    parseInt(record.total_receipts, 10),
                    parseInt(record.total_disbursements, 10),
                    parseInt(record.cash_on_hand_end_period, 10)
                ]
                return row
            }
            finally {
                // do nothing
            }
        })
        return chartedResults
    }

    senateStripResults = (results) => {
        let storedResults = {}
        results.forEach((result) => {
            let reportName = result.report_name
            let reportFiledDate = result.receipt_date
            if (!storedResults[reportName]) {
                storedResults[reportName] = reportFiledDate
            } else if (storedResults[reportName] < reportFiledDate) {
                storedResults[reportName] = reportFiledDate
            }
        })
        let finalReports = []
        results.forEach((record) => {
            if(storedResults[record.report_name] === record.receipt_date) {
                finalReports.push(record)
            }
        })
        return finalReports
    }

    render() {

        const data = this.state.results
        const chartData = this.state.chartData
        const chartColumns = ["Date", "Contributions", "Disbursements", "Cash On Hand"]
        const chartWhole = [chartColumns, ...chartData]

        const displayColumns = [{
            Header: 'Report',
            accessor: 'report_title',
            Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }}></div>)
        },{
            Header: 'Recipts',
            accessor: 'total_receipts',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Disbursements',
            accessor: 'total_disbursements',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Debt',
            accessor: 'debt',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Ending Cash On Hand',
            accessor: 'cash_on_hand_end_period',
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Coverage Start Date',
            accessor: 'coverage_start_date',
            Cell: row => (functions.parseDate(row.value)),
            className: 'numeric'
        },{
            Header: 'Coverage End Date',
            accessor: 'coverage_end_date',
            Cell: row => (functions.parseDate(row.value)),
            className: 'numeric'
        }]

        const options = {
            legend: { position: "bottom" },
            vAxis: { title: 'Amount', format: 'currency' },
            hAxis: { title: 'Coverage End Date'},
          };

        return(
            <div>
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    this.state.errorText &&
                    <h3>{this.state.errorText}</h3>
                }
                {
                    (this.state.results && !this.state.loading) ?
                    <div>
                    <h2>Contributions And Disbursements</h2>
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={chartWhole}
                        options={options}
                    />
                    <br />
                    </div> : null
                }
                {
                    (this.state.results && !this.state.loading) ?
                    <h2>Regular Reports Filed</h2> : 
                    null
                }
                {
                    (data && !this.state.loading) ?
                    <ReactTable
                        data={data}
                        columns={displayColumns}
                        showPagination={false}
                        defaultPageSize={data.length}
                        className="-striped -highlight"
                        resizable={false}
                        defaultSorted={[{
                            id: 'coverage_end_date',
                            desc: true,
                          }]}
                    /> : 
                    null
                }
            </div>
        )
    }
}

export default Summary