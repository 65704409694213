import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import * as functions from '../constants/functions'
import ReactLoading from 'react-loading'
import {makeRequestForFECData} from '../constants/api'
import ReactTable from 'react-table'
import {CSVLink} from 'react-csv'
import 'react-table/react-table.css'

class DisplayDisbursements extends Component {

    constructor () {
        super () 
        this.state = {
            searchTextDisplay: null,
            searchParams: null,
            results: null,
            loading: false,
            currentCycle: functions.currentCycle
        }
      }

    callDisbursementSearch = (params, results, all_cycles) => {
        const endpoint = '/schedules/schedule_b/'
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results) {
                const formattedResults = data.results.map((record) => {
                    const row = ({
                        'recipient_name': record['recipient_name'] || "None Listed",
                        'recipient_city': record['recipient_city'] || "None Listed",
                        'recipient_state': record['recipient_state'] || "None Listed",
                        'recipient_zip': functions.reduceZip(record['recipient_zip']) || "None Listed",
                        'purpose': record['disbursement_description'] || "None Listed",
                        'committee': `<a href="${record['pdf_url']}" target="_blank">${record['committee']['name']}</a>`,
                        'party': record['committee']['party'] || 'N/A',
                        'date': record['disbursement_date'] || 'N/A',
                        'amount': record['disbursement_amount'] || '0.00',
                        'committee_name': record['committee']['name'],
                        'date_parsed': functions.parseDate(record['disbursement_date']) || 'N/A',
                        'link': record['pdf_url'] || 'N/A'
                    })
                    return row
                }) 
                results.push(...formattedResults)
                if (data.pagination.last_indexes && data.pagination.last_indexes.last_disbursement_date && data.pagination.last_indexes.last_index) {
                    params['last_index'] = data.pagination.last_indexes.last_index
                    params['last_disbursement_date'] = data.pagination.last_indexes.last_disbursement_date
                    this.callDisbursementSearch(params, results, all_cycles)
                } else if (params['two_year_transaction_period'] > 1978 && all_cycles) {
                    params['two_year_transaction_period'] -= 2
                    this.callDisbursementSearch(params, results, all_cycles)
                } else {
                    if (results.length === 0) {
                        this.setState({
                            results: null,
                            loading: false,
                            searchTextDisplay: `No Disbursements Found To ${this.state.searchTitle}`
                        })
                    } else {
                        this.setState({
                            results: results,
                            loading: false,
                            searchTextDisplay: `Disbursements To ${this.state.searchTitle} (${results.length} total results)`
                        })
                    }
                }
            } else {
                this.setState({
                    results: null,
                    loading: false,
                    searchTextDisplay: `There was an error retrieving the results for your search. Please try again in a moment.`
                })
            }
        })
    }

    getSearchResults = (search) => {
        let params = qs.parse(search)
        if(!params['recipient_name'] && !params['disbursement_description']) { return }
        let all_cycles = false
        if (params['cycle'] === 'all') {
            params['two_year_transaction_period'] = this.state.currentCycle
            delete params['cycle']
            all_cycles = true
        } else {
            params['two_year_transaction_period'] = params['cycle']
            delete params['cycle']
        }
        params['per_page'] = 100
        params['sort'] = '-disbursement_date'
        this.setState({
            searchParams: params,
            loading: true
        })
        if(params['recipient_name']) {
            this.setState({ searchTitle: params['recipient_name']})
        } else {
            this.setState({ searchTitle: params['disbursement_description']})
        }
        this.callDisbursementSearch(params, [], all_cycles)
    }
    
    componentDidMount() {
        this.getSearchResults(this.props.match.params.search)
    }

    componentWillReceiveProps (nextProps) {
        this.getSearchResults(nextProps.match.params.search)
    }

    prepareCSVData = (data) => {
        if (data) {
            const csvArray = data.map((record) => {
                let row = []
                row.push(
                    record['recipient_name'],
                    record['recipient_city'],
                    record['recipient_state'],
                    record['recipient_zip'],
                    record['purpose'],
                    record['committee_name'],
                    record['party'],
                    record['date_parsed'],
                    record['amount'],
                    record['link']
                )
                return row
            })
            return csvArray    
        }
    }

    render() {

        const csvColumns = [
            'recipient_name',
            'recipient_city',
            'recipient_state',
            'recipient_zip',
            'purpose',
            'committee_name',
            'party',
            'date',
            'amount',
            'link'
        ]

        const displayColumns = [{
            Header: 'Name',
            accessor: 'recipient_name',
            width: 140
        },{
            Header: 'City',
            accessor: 'recipient_city'
        },{
            Header: 'State',
            accessor: 'recipient_state',
            width: 50
        },{
            Header: 'Zip',
            accessor: 'recipient_zip',
            width: 50
        },{
            Header: 'Purpose',
            accessor: 'purpose'
        },{
            Header: 'Committee',
            accessor: 'committee',
            Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }}></div>)
        },{
            Header: 'Party',
            accessor: 'party',
            width: 50
        },{
            Header: 'Date',
            accessor: 'date',
            width: 60,
            Cell: row => (functions.parseDate(row.value)),
            className: 'numeric'
        },{
            Header: 'Amount',
            accessor: 'amount',
            width: 100,
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        }]

        const data = this.state.results
        const csvData = this.prepareCSVData(data)

        return(
            <div className="container card results">
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    (this.state.searchTextDisplay && !this.state.loading) ?
                    <h3>{this.state.searchTextDisplay}</h3> : 
                    null
                }
                {
                    (data && !this.state.loading) ?
                    <span className="download-link"><CSVLink data={csvData} headers={csvColumns} filename="contributions.csv" className="download-link" >Download Data <i className="material-icons">get_app</i></CSVLink></span> : 
                    null
                }
                {
                    (data && !this.state.loading) ?
                    <ReactTable
                        data={data}
                        columns={displayColumns}
                        showPagination={false}
                        defaultPageSize={data.length}
                        className="-striped -highlight"
                        resizable={false}
                    /> : 
                    null
                }
            </div>
        )
    }
}

export default withRouter(DisplayDisbursements)