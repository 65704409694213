import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import Summary from '../../components/committee/summary'
import Employers from '../../components/committee/employers'
import States from '../../components/committee/states'
import Purpose from '../../components/committee/purpose'
import Recipient from '../../components/committee/recipient'
import {makeRequestForFECData} from '../../constants/api'

class HouseRaceReport extends Component {

    constructor (props) {
        super (props) 
        this.state = {
        }
    }

    getCommitteeInfo = (committeeID) => {
        const endpoint = `/committee/${committeeID}`
        const params = {
            'per_page': 1
        }
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results && data.results.length > 0) {
                const committeeInfo = data.results[0]
                this.setState({
                    'titleCard': committeeInfo.name,
                })
            } else {
                this.setState({
                    'titleCard': `No committee found using the ID ${this.state.committeeID}`
                })
            }
        })
    }

    componentDidMount() {
        const committeeID = this.props.match.params.committeeID
        const cycle = this.props.match.params.cycle || null
        this.setState({'committeeID':committeeID, 'cycle':cycle})
        this.getCommitteeInfo(committeeID)
    }

    render() {

        const committeeID = this.state.committeeID
        const cycle = this.state.cycle
        const titleCard = this.state.titleCard

        return(
            <div>
                <div className="container card results">
                    { titleCard && <h1>{titleCard}</h1>}
                    <Summary committeID={committeeID} cycle={cycle}  />
                </div>
                <div className="container card results">
                    <h1>Contributors</h1>
                    <Employers committeID={committeeID} cycle={cycle}  />
                    <br />
                    <States committeID={committeeID} cycle={cycle} />
                </div>
                <div className="container card results">
                    <h1>Disbursements</h1>
                    <Recipient committeID={committeeID} cycle={cycle}  />
                    <br />
                    <Purpose committeID={committeeID} cycle={cycle}  />
                </div>
            </div>
        )
    }

}

export default withRouter(HouseRaceReport)