export const districts = {
    "Alabama": {
        "short": "AL",
        "seats": 7
        },
    "Alaska": {
        "short": "AK",
        "seats": 1
        },
    "Arizona": {
        "short": "AZ",
        "seats": 9
        },
    "Arkansas": {
        "short": "AR",
        "seats": 4
        },
    "California": {
        "short": "CA",
        "seats": 53
        },
    "Colorado": {
        "short": "CO",
        "seats": 7
        },
    "Connecticut": {
        "short": "CT",
        "seats": 5
        },
    "Delaware": {
        "short": "DE",
        "seats": 1
        },
    "District of Columbia": {
        "short": "DC",
        "seats": 1
        },
    "Florida": {
        "short": "FL",
        "seats": 27
        },
    "Georgia": {
        "short": "GA",
        "seats": 14
        },
    "Hawaii": {
        "short": "HI",
        "seats": 2
        },
    "Idaho": {
        "short": "ID",
        "seats": 2
        },
    "Illinois": {
        "short": "IL",
        "seats": 18
        },
    "Indiana": {
        "short": "IN",
        "seats": 9
        },
    "Iowa": {
        "short": "IA",
        "seats": 4
        },
    "Kansas": {
        "short": "KS",
        "seats": 4
        },
    "Kentucky": {
        "short": "KY",
        "seats": 6
        },
    "Louisiana": {
        "short": "LA",
        "seats": 6
        },
    "Maine": {
        "short": "ME",
        "seats": 2
        },
    "Maryland": {
        "short": "MD",
        "seats": 8
        },
    "Massachusetts": {
        "short": "MA",
        "seats": 9
        },
    "Michigan": {
        "short": "MI",
        "seats": 14
        },
    "Minnesota": {
        "short": "MN",
        "seats": 1
        },
    "Mississippi": {
        "short": "MS",
        "seats": 4
        },
    "Missouri": {
        "short": "MO",
        "seats": 8
        },
    "Montana": {
        "short": "MT",
        "seats": 1
        },
    "Nebraska": {
        "short": "NE",
        "seats": "3",
        },
    "Nevada": {
        "short": "NV",
        "seats": 4
        },
    "New Hampshire": {
        "short": "NH",
        "seats": 2
        },
    "New Jersey": {
        "short": "NJ",
        "seats": 12
        },
    "New Mexico": {
        "short": "NM",
        "seats": 3
        },
    "New York": {
        "short": "NY",
        "seats": 27
        },
    "North Carolina": {
        "short": "NC",
        "seats": 13
        },
    "North Dakota": {
        "short": "ND",
        "seats": 1
        },
    "Ohio": {
        "short": "OH",
        "seats": 16
        },
    "Oklahoma": {
        "short": "OK",
        "seats": 5
        },
    "Oregon": {
        "short": "OR",
        "seats": 5
        },
    "Pennsylvania": {
        "short": "PA",
        "seats": 18
        },
    "Rhode Island": {
        "short": "RI",
        "seats": 2
        },
    "South Carolina": {
        "short": "SC",
        "seats": 7
        },
    "South Dakota": {
        "short": "SD",
        "seats": 1
        },
    "Tennessee": {
        "short": "TN",
        "seats": 9
        },
    "Texas": {
        "short": "TX",
        "seats": 36
        },
    "Utah": {
        "short": "UT",
        "seats": 4
        },
    "Vermont": {
        "short": "VT",
        "seats": 1
        },
    "Virginia": {
        "short": "VA",
        "seats": 11
        },
    "Washington": {
        "short": "WA",
        "seats": 10
        },
    "West Virginia": {
        "short": "WV",
        "seats": 3
        },
    "Wisconsin": {
        "short": "WI",
        "seats": 8
        },
    "Wyoming": {
        "short": "WY",
        "seats": "1"
        }
}