import React, {Component} from 'react'
import * as routes from '../constants/routes';
import * as functions from '../constants/functions'
import {districts} from '../constants/congressionaldistricts';

class SearchElections extends Component {

    constructor () {
        super()
        this.state = { 
            office: 'senate',
            state: 'Alabama',
            district: '01',
            cycle: functions.currentCycle,
        }
    }

    renderCycles = () => {
        const baseCycle = 1978
        const maxCycle = functions.currentCycle + 2
        let cycles = []
        for(let i = maxCycle; i >= baseCycle; i -= 2) {
            cycles.push(
                <option value={i} key={i}>
                    {i}
                </option>
            )
        }
        return cycles
    }

    renderDistricts = (n) => {
        let districts = []
        if (n === 1) {
            districts.push(<option value='00' key='00'>At-Large</option>)
            return districts
        }
        for(let i=1; i<=n; i++) {
            let x = i.toString()
            if (i < 10) {
                x = '0' + x
            }
            districts.push(
                <option value={x} key={x}>
                    District {x}
                </option>
            )
        }
        return districts
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const office = this.state.office
        const state = districts[this.state.state].short
        const district = this.state.district
        const cycle = this.state.cycle

        let queryParams = `office=${office}&cycle=${cycle}`

        if (office === 'house' || office === 'senate') {
            queryParams += `&state=${state}`
            if (office === 'house') {
                if (districts[this.state.state].seats === 1) {
                    queryParams += `&district=00`
                } else {
                    queryParams += `&district=${district}`
                }
            }
        } 
        this.props.history.push(`${routes.ELECTIONS}/${queryParams}`)
    }

    handleChange = (e) => {
        const target = e.target
        const val = target.value
        const targetID = target.id
        this.setState({
            [targetID]: val.replace(/[^\w\s]/gi, '')
        })
        if (targetID === 'statename') {
            if (districts[val].seats === 1) {
                this.setState({ district: '00' })
            } else {
                this.setState({ district: '01' })
            }
        }
    }

    render () {

        const currentCycle = functions.currentCycle

        return(
            <div className="container card">
                <h2>Search FEC For Election Summary</h2>
                <form className="search-form form" onSubmit={this.handleSubmit}>
                    <span className="search-text">Election Cycle:</span><select id="cycle" onChange={this.handleChange} defaultValue={currentCycle}>
                        {
                            this.renderCycles().map((cycle) => {
                                return cycle
                            })
                        }
                    </select>
                    <span className="search-text">Office:</span><select id="office" onChange={this.handleChange} defaultValue="senate">
                        <option value="president">President</option>
                        <option value="senate">Senate</option>
                        <option value="house">House</option>
                    </select>
                    {   
                        (this.state.office === 'house' || this.state.office === 'senate') && 
                        <span>
                            <span className="search-text">State:</span>
                            <select id="state" onChange={this.handleChange}>
                                {Object.keys(districts).map((key, keyIndex) => {
                                    return <option value={key} key={key}>{key}</option>
                                })}
                            </select>
                        </span>
                    }
                    
                    {
                        this.state.office === 'house' &&
                        <span>
                        <span className="search-text">District:</span>
                        <select id="district" onChange={this.handleChange}>
                        {this.renderDistricts(districts[this.state.state].seats).map((district) => {
                            return district
                        })}
                        </select>
                        </span>
                    }
                    <button type="submit" className="search-button"><i className="material-icons">search</i> Search Election</button>
                </form>
                <br />
            </div>
        )
    }
}

export default SearchElections