import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import * as functions from '../constants/functions'
import ReactLoading from 'react-loading'
import {makeRequestForFECData} from '../constants/api'
import ReactTable from 'react-table'
import {CSVLink} from 'react-csv'
import 'react-table/react-table.css'

class DisplayContributions extends Component {

    constructor () {
        super () 
        this.state = {
            searchTextDisplay: null,
            results: null,
            loading: false,
            currentCycle: functions.currentCycle
        }
      }

    callContributionSearch = (params, results, all_cycles) => {
        const endpoint = '/schedules/schedule_a/'
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results) {
                const formattedResults = data.results.map((record) => {
                    const row = ({
                        'contributor_name': record['contributor_name'] || "None Listed",
                        'contributor_street': record['contributor_street_2'] ? record['contributor_street_1'] + ' ' + record['contributor_street_2'] : record['contributor_street_1'],
                        'contributor_city': record['contributor_city'] || "None Listed",
                        'contributor_state': record['contributor_state'] || "None Listed",
                        'contributor_zip': functions.reduceZip(record['contributor_zip']) || "None Listed",
                        'employeroccupation': `${record['contributor_occupation'] || "None Listed"}, ${record['contributor_employer'] || "None Listed"}`,
                        'committee': (record['memo_text'] && record['memo_text'].includes('EARMARKED FOR ') && record['committee']) ? 
                            `<a href="${record['pdf_url']}" target="_blank">${record['memo_text'].replace('EARMARKED FOR ','')} VIA ${record['committee']['name']}</a>` : 
                            `<a href="${record['pdf_url']}" target="_blank">${record['committee']['name']}</a>`,
                        'party': record['committee']['party'] || 'N/A',
                        'date': record['contribution_receipt_date'] || 'N/A',
                        'amount': record['contribution_receipt_amount'] || '0.00',
                        'committee_name': (record['memo_text'] && record['memo_text'].includes('EARMARKED FOR ') && record['committee']) ? 
                            `${record['memo_text'].replace('EARMARKED FOR ','')} VIA ${record['committee']['name']}` : 
                            `${record['committee']['name']}`,
                        'date_parsed': functions.parseDate(record['contribution_receipt_date']) || 'N/A',
                        'link': record['pdf_url'] || 'N/A'
                    })
                    return row
                }) 
                results.push(...formattedResults)
                if (data.pagination.last_indexes) {
                    params['last_index'] = data.pagination.last_indexes.last_index
                    params['last_contribution_receipt_date'] = data.pagination.last_indexes.last_contribution_receipt_date
                    this.callContributionSearch(params, results, all_cycles)
                } else if (params['two_year_transaction_period'] > 1978 && all_cycles) {
                    params['two_year_transaction_period'] -= 2
                    this.callContributionSearch(params, results, all_cycles)
                } else {
                    if (results.length === 0) {
                        this.setState({
                            results: null,
                            loading: false,
                            searchTextDisplay: `No Contributions Found From ${this.state.searchTitle}`
                        })
                    } else {
                        this.setState({
                            results: results,
                            loading: false,
                            searchTextDisplay: `Contributions From ${this.state.searchTitle} (${results.length} total results)`
                        })
                    }
                }
            } else {
                this.setState({
                    results: null,
                    loading: false,
                    searchTextDisplay: `There was an error retrieving the results for your search. Please try again in a moment.`
                })
            }
        })
    }

    getSearchResults = (search) => {
        let params = qs.parse(search)
        if(!params['contributor_name'] && !params['contributor_employer']) { return }
        let all_cycles = false
        if (params['cycle'] === 'all') {
            params['two_year_transaction_period'] = this.state.currentCycle
            delete params['cycle']
            all_cycles = true
        } else {
            params['two_year_transaction_period'] = params['cycle']
            delete params['cycle']
        }
        params['per_page'] = 100
        params['sort'] = '-contribution_receipt_date'
        this.setState({
            loading: true
        })
        if(params['contributor_name']) {
            this.setState({ searchTitle: params['contributor_name']})
        } else {
            this.setState({ searchTitle: params['contributor_employer']})
        }
        this.callContributionSearch(params, [], all_cycles)
    }
    
    componentDidMount() {
        this.getSearchResults(this.props.match.params.search)
    }

    componentWillReceiveProps (nextProps) {
        this.getSearchResults(nextProps.match.params.search)
    }

    prepareCSVData = (data) => {
        if (data) {
            const csvArray = data.map((record) => {
                let row = []
                row.push(
                    record['contributor_name'],
                    record['contributor_street'],
                    record['contributor_city'],
                    record['contributor_state'],
                    record['contributor_zip'],
                    record['employeroccupation'],
                    record['committee_name'],
                    record['party'],
                    record['date_parsed'],
                    record['amount'],
                    record['link']
                )
                return row
            })
            return csvArray    
        }
    }

    render() {

        const csvColumns = [
            'contributor_name',
            'contributor_street',
            'contributor_city',
            'contributor_state',
            'contributor_zip',
            'employeroccupation',
            'recipient_committee',
            'party',
            'date',
            'amount',
            'link'
        ]

        const displayColumns = [{
            Header: 'Name',
            accessor: 'contributor_name',
            width: 140
        },{
            Header: 'City',
            accessor: 'contributor_city'
        },{
            Header: 'State',
            accessor: 'contributor_state',
            width: 50
        },{
            Header: 'Zip',
            accessor: 'contributor_zip',
            width: 50
        },{
            Header: 'Employer/Occupation',
            accessor: 'employeroccupation'
        },{
            Header: 'Recipient',
            accessor: 'committee',
            Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }}></div>)
        },{
            Header: 'Party',
            accessor: 'party',
            width: 50
        },{
            Header: 'Date',
            accessor: 'date',
            width: 60,
            Cell: row => (functions.parseDate(row.value)),
            className: 'numeric'
        },{
            Header: 'Amount',
            accessor: 'amount',
            width: 100,
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        }]

        const data = this.state.results
        const csvData = this.prepareCSVData(data)

        return(
            <div className="container card results">
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    (this.state.searchTextDisplay && !this.state.loading) ?
                    <h3>{this.state.searchTextDisplay}</h3> : 
                    null
                }
                {
                    (data && !this.state.loading) ?
                    <span className="download-link"><CSVLink data={csvData} headers={csvColumns} filename="contributions.csv" className="download-link" >Download Data <i className="material-icons">get_app</i></CSVLink></span> : 
                    null
                }
                {
                    (data && !this.state.loading) ?
                    <ReactTable
                        data={data}
                        columns={displayColumns}
                        showPagination={false}
                        defaultPageSize={data.length}
                        className="-striped -highlight"
                        resizable={false}
                    /> : 
                    null
                }
            </div>
        )
    }
}

export default withRouter(DisplayContributions)