import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import * as routes from '../constants/routes';
import * as functions from '../constants/functions'

class SearchCandidatesAndCommittees extends Component {

    constructor () {
        super()
        this.state = { 
            cycle: functions.currentCycle,
        }
    }

    renderCycles = () => {
        const baseCycle = 1978
        const maxCycle = functions.currentCycle
        let cycles = []
        for(let i = maxCycle; i >= baseCycle; i -= 2) {
            cycles.push(
                <option value={i} key={i}>
                    {i}
                </option>
            )
        }
        return cycles
    }

    validateParams = () => {
        return (
            (this.state.q && this.state.q.replace(/ /g, "").length >= 4)
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.validateParams()) {
            let queryParams = Object.keys(this.state).map((key) => {
                let value = this.state[key]
                if (value.length > 0) { 
                    return `${key}=${value.replace(/ +(?= )/g,'')}` 
                }
            })
            queryParams = queryParams.filter((n)=> { return n !== undefined }).join('&')
            this.props.history.push(`${routes.COMMITTEES}/search/${queryParams}`)
        }
    }

    handleChange = (e) => {
        const target = e.target
        const val = target.value
        const targetID = target.id
        this.setState({
            [targetID]: val.replace(/[^\w\s]/gi, '')
        })
    }

    render () {

        return(
            <div className="container card">
                <h2>Search FEC For Candidates And Committees</h2>
                <form className="search-form form" onSubmit={this.handleSubmit}>
                    <input className="input __text" id="q" onChange={this.handleChange} maxLength="100" placeholder="Candidate or Committee Name (minimum 4 characters)" size="80"/>
                    <br />
                    <button type="submit" className="search-button"><i className="material-icons">search</i> Search For Disbursements</button>
                </form>
                <br />
            </div>
        )
    }
}

export default withRouter(SearchCandidatesAndCommittees)