import React, {Component} from 'react'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'
import * as functions from '../constants/functions'
import ReactLoading from 'react-loading'
import {makeRequestForFECData} from '../constants/api'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import * as routes from '../constants/routes';

class DisplayElections extends Component {

    constructor () {
        super () 
        this.state = {
            searchTextDisplay: null,
            searchParams: null,
            results: null,
            loading: false,
        }
      }

    callElectionSearch = (params, results) => {
        const endpoint = '/elections/'
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results) {
                const formattedResults = data.results.map((record) => {
                    let row = ({
                        'name': record.candidate_name,
                        'candidate_id': record.candidate_id,
                        'party': record.party_full || "N/A",
                        'status': record.incumbent_challenge_full,
                        'raised': record.total_receipts,
                        'spent': record.total_disbursements,
                        'cash': record.cash_on_hand_end_period,
                        'last_filed': record.coverage_end_date,
                        'committee_id': record.candidate_pcc_id || '',
                        'candidate_committee': <span><a href={'https://www.fec.gov/data/candidate/' + record.candidate_id } target="_blank" rel="noopener noreferrer">{record.candidate_name}</a> <br /><Link to={'../..' + routes.COMMITTEES + '/id/' + record.candidate_pcc_id}>Campaign Committee: {record.candidate_pcc_id || ''}</Link></span>
                    })
                    return row
                })
                results.push(...formattedResults)
                this.setState({ loading: false })
                if (results.length === 0) {
                    this.setState({
                        results: results,
                        searchTextDisplay: `There Are No ${this.setRaceText(params)}`
                    })
                } else {
                    this.setState({
                        results: results,
                        searchTextDisplay: this.setRaceText(params)
                    })
                }
            } else {
                this.setState({
                    results: [],
                    searchTextDisplay: 'There was an error retreiving candidates for this election. Please try again.'
                })
            }
        })
    }

    setRaceText = (p) => {
        if (p.office === 'president') {
            return `Candidates For President In ${p.cycle}`
        } else if (p.office === 'senate') {
            return `Candidates For The Senate In ${p.state} In ${p.cycle}`
        } else {
            if (p.district === '00') {
                return `Candidates For The House In ${p.state}-AL In ${p.cycle}`
            }
            return `Candidates For The House In ${p.state}-${p.district} In ${p.cycle}`
        }
    }

    getSearchResults = (search) => {
        let params = qs.parse(search)
        params['sort'] = '-cash_on_hand_end_period'
        params['per_page'] = '100'

        this.setState({
            searchParams: params,
            loading: true
        })
        this.callElectionSearch(params, [])
    }
    
    componentDidMount() {
        this.getSearchResults(this.props.match.params.search)
    }

    componentWillReceiveProps (nextProps) {
        this.getSearchResults(nextProps.match.params.search)
    }

    
    render() {

        const displayColumns = [{
            Header: 'Candidate',
            accessor: 'candidate_committee',
            width: 250,
        },{
            Header: 'Party',
            accessor: 'party',
            Cell: row => (row.value.substring(0,3)),
            width: 50
        },{
            Header: 'Receipts',
            accessor: 'raised',
            width: 120,
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Disbursements',
            accessor: 'spent',
            width: 120,
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Cash On Hand',
            accessor: 'cash',
            width: 120,
            Cell: row => (functions.toCurrency(row.value)),
            className: 'numeric'
        },{
            Header: 'Reported Through',
            accessor: 'last_filed',
            Cell: row => (functions.parseDate(row.value)),
            className: 'numeric'
        }]

        const data = this.state.results

        return(
            <div className="container card results">
                {
                    this.state.loading &&
                    <div className="loading">
                        <ReactLoading type="bars" color="#BE2931"/>
                    </div>
                }
                {
                    (this.state.searchTextDisplay && !this.state.loading) ?
                    <h3>{this.state.searchTextDisplay}</h3> : 
                    null
                }
                {
                    (data && data.length > 0 && !this.state.loading) ?
                    <ReactTable
                        data={data}
                        columns={displayColumns}
                        showPagination={false}
                        defaultPageSize={data.length}
                        className="-striped -highlight"
                        resizable={false}
                    /> : 
                    null
                }
            </div>
        )
    }
}

export default withRouter(DisplayElections)