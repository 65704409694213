import React, {Component} from 'react'
import { withRouter, Link } from 'react-router-dom'
import {makeRequestForFECData} from '../constants/api'
import CommitteeSummary from '../views/committee/committeesummary'
import * as functions from '../constants/functions'

class Committee extends Component {

    constructor () {
        super () 
        this.state = {
            'committeeID': null,
            'titleCard': null,
            'form_type': null,
            'activeHouseCommittee': false
        }
      }

    getCommitteeInfo = (committeeID) => {
        const endpoint = `/committee/${committeeID}`
        const params = {
            'per_page': 1
        }
        makeRequestForFECData(endpoint, params).then(data => {
            if (data.results && data.results.length > 0) {
                const committeeInfo = data.results[0]
                let activeHouseCommittee = committeeInfo.committee_type === 'H' && committeeInfo.cycles.includes(functions.currentCycle)
                this.setState({
                    'titleCard': committeeInfo.name,
                    'fecPage': <a href={'https://www.fec.gov/data/committee/' + committeeInfo.committee_id + '/?tab=filings'} target="_blank" rel="noopener noreferrer">FEC Files</a>,
                    'committeeType': committeeInfo.committee_type,
                    'activeHouseCommittee': activeHouseCommittee
                })
            } else {
                this.setState({
                    'titleCard': `No committee found using the ID ${this.state.committeeID}`
                })
            }
        })
    }

    componentDidMount() {
        const committeeID = this.props.match.params.committeeID
        const cycle = this.props.match.params.cycle || null
        this.setState({'committeeID':committeeID, 'cycle':cycle})
        this.getCommitteeInfo(committeeID)
    }

    render() {

        const committeeID = this.state.committeeID
        const titleCard = this.state.titleCard
        const cycle = this.state.cycle
        const committeeType = this.state.committeeType
        const activeHouseCommittee = this.state.activeHouseCommittee

        return(
            <div>
                <div className="container card results">
                    <h1>{titleCard}</h1>
                    { activeHouseCommittee && 
                        <span className="committee-links">
                            {this.state.fecPage} < br />
                            <Link to={'../id/summary/' + committeeID + '/' + functions.currentCycle} >Click to view current race report</Link>
                        </span>
                    }
                    { !activeHouseCommittee && <span className="committee-links">{this.state.fecPage}</span>}
                    <CommitteeSummary committeID={committeeID} cycle={cycle} committeeType={committeeType}/>
                </div>
            </div>
        )
    }
}


export default withRouter(Committee)