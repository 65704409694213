import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import * as routes from '../constants/routes';
import * as functions from '../constants/functions'

class SearchDisbursements extends Component {

    constructor () {
        super()
        this.state = { 
            cycle: functions.currentCycle,
        }
    }

    renderCycles = () => {
        const baseCycle = 1978
        const maxCycle = functions.currentCycle
        let cycles = []
        for(let i = maxCycle; i >= baseCycle; i -= 2) {
            cycles.push(
                <option value={i} key={i}>
                    {i}
                </option>
            )
        }
        return cycles
    }

    validateParams = () => {
        return (
            (this.state.recipient_name && this.state.recipient_name.replace(/ /g, "").length >= 4) ||
            (this.state.disbursement_description  && this.state.disbursement_description.replace(/ /g, "").length >= 4)
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.validateParams()) {
            let queryParams = Object.keys(this.state).map((key) => {
                let value = this.state[key]
                if (value.length > 0) { 
                    return `${key}=${value.replace(/ +(?= )/g,'')}` 
                }
            })
            queryParams = queryParams.filter((n)=> { return n !== undefined }).join('&')
            this.props.history.push(`${routes.DISBURSEMENTS}/${queryParams}`)
        }
    }

    handleChange = (e) => {
        const target = e.target
        const val = target.value
        const targetID = target.id
        this.setState({
            [targetID]: val.replace(/[^\w\s]/gi, '')
        })
    }

    render () {

        return(
            <div className="container card">
                <h2>Search FEC For Disbursements</h2>
                <form className="search-form form" onSubmit={this.handleSubmit}>
                    <input className="input __text" id="recipient_name" onChange={this.handleChange} maxLength="100" placeholder="Payee Name (minimum 4 characters)" size="80"/>
                    <span className="search-text">Select Cycle:</span><select id="cycle" onChange={this.handleChange} defaultValue={functions.currentCycle}>
                    <option value='all' key='all' >All Cycles</option>
                    {
                        this.renderCycles().map((cycle) => {
                            return cycle
                        })
                    }
                    </select>
                    <br />
                    <input className="input __text" id="disbursement_description" onChange={this.handleChange} maxLength="50" placeholder="Purpose (minimum 4 characters)" size="80" />
                    <br />
                    <input className="input __text" id="recipient_city" onChange={this.handleChange} maxLength="30" placeholder="City" size="65" />
                    <input className="input __text" id="recipient_state" onChange={this.handleChange} maxLength="2" placeholder="State (2 digit)" size="16" />
                    <input className="input __text" id="recipient_zip" onChange={this.handleChange} maxLength="5" placeholder="Zipcode (5 digit)" size="20" />
                    <br />
                    <button type="submit" className="search-button"><i className="material-icons">search</i> Search For Disbursements</button>
                    
                    
                    <span className="input-notation">Note: you must include either a payee name or purpose. Searching "All Cycles" will be very slow, so you may want to select specific years whenever possible.</span>
                </form>
            </div>
        )
    }
}

export default withRouter(SearchDisbursements)