import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';

const Navigation = () =>
    <div className="navigation card">
        <ul>
            <li><Link to={routes.CONTRIBUTIONS}>Contributions</Link></li> 
            <li><Link to={routes.DISBURSEMENTS}>Disbursements</Link></li>
            <li><Link to={routes.COMMITTEES + "/search"}>Candidates & Committees</Link></li>
            <li><Link to={routes.ELECTIONS}>Elections</Link></li>
        </ul>
    </div>

export default Navigation;