import axios from 'axios'
import {keys} from './keys.js'

const api = axios.create({
  baseURL: 'https://api.open.fec.gov/v1'
})

export const makeRequestForFECData = (endpoint, searchParams) => {
    searchParams['api_key'] = keys[Math.floor(Math.random() * keys.length)]
    return api.get(endpoint, { 
        params: searchParams
    }).then(result => {
        return result.data
    }).catch(err => {
        console.error(err)
        return err
    })
}